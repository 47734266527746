import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';

import logo1 from '../Images/customers/waaree.png';
import logo2 from '../Images/customers/Pavers-England-Logo.jpg';
import logo3 from '../Images/customers/semikron-logo.png';
import logo4 from '../Images/customers/unique air express logo.jpg';
import logo5 from '../Images/customers/biomex-instruments-private-limited-120x120.png';
import logo6 from '../Images/customers/gf inc.png';
import logo7 from '../Images/customers/vin-semiconductors-private-limited-logo-120x120.png';

import logo8 from '../Images/customers/loreal-logo-png-5323615.png';
import logo9 from '../Images/customers/bhatia_shipping_logo.gif';
import logo10 from '../Images/customers/elementto logo.jpg';
import logo11 from '../Images/customers/Kaymo_Logo-dk.jpg';
import logo12 from '../Images/customers/hamilton logo.png';
import logo13 from '../Images/customers/cocacola_logo_PNG10.png';
import logo14 from '../Images/customers/vital logo.png';

import logo15 from '../Images/customers/htr_logo.jpg';
import logo16 from '../Images/customers/TITAN-ASIA-VARIANTE-ombre.png';
import logo17 from '../Images/customers/godrej_logo.gif';
import logo18 from '../Images/customers/biopac logo.jpg';
import logo19 from '../Images/customers/dynamic-logo.jpg';
import logo20 from '../Images/customers/tgls-logo.jpg';

function IndustryConnect() {
  return (
    <Container fluid className="m-0 p-0 w-100">
      <Jumbotron fluid className=" m-0 industry-jumbo ">
        <Row className="h-100">
          <div className="col-auto h-100 w-100 vertical-center">
            <h1 className="h-100 vertical-center">Industry Connect</h1>
          </div>
        </Row>
      </Jumbotron>

      <Row className="w-100 m-0 locnpar">
        <Row className="w-100 h-100">
          <Col><h1>GRP Challenges</h1></Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
              <h5 className="col-12">
                <li>Coordination with Multiple Vendors</li>
              </h5>
            </ul>
          </Col>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
              <h5 className="col-12">
                <li>Truckers' Management</li>
              </h5>
            </ul>
          </Col>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
              <h5 className="col-12">
                <li>Invoicing Hassles</li>
              </h5>
            </ul>
          </Col>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
            <h5 className="col-12">
              <li>Payables Management</li>
            </h5>
            </ul>
          </Col>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
            <h5 className="col-12">
              <li>Increasing Staff Costs & Vacations</li>
            </h5>
            </ul>
          </Col>
          <Col xs={12} sm={6} className="w-100 py-3">
            <ul className="list-unstyled">
              <h5 className="col-12">
                <li>Rising Human Errors</li>
              </h5>
            </ul>
          </Col>
        </Row>
      </Row>

      <Row className="w-100 m-0 locnpar grey-background">
        <h1 className="col-12">How GDCM can help You</h1>
        <ul style={{"text-align":"justify", "padding-top":"2%"}}>
          <li>Organizations can cut down on costs as expenses arising out of employment of additional  resources</li>
          <li>The management can also be involved in the discussions and take decisions clearly.</li>
          <li>Single window concept of business dealing possible as dedicated support is available</li>
          <li>Maximum audience reach globally without being physically present</li>
          <li>Helps in gaining edge over competitors</li>
          <li>Low costs involved for availing services with GDCM Logistics Pvt ltd, gives organizations  of all levels a secured environment</li>
          <li>GDCM logistics solutions offer support 24*7*365 services with almost zero downtime.</li>
          <li>Highly skilled and trained staff for complex services.</li>
          <li>Minimal errors resulting in less wastages and higher returns for the company.</li>
        </ul>
      </Row>
    </Container>
  );
}

export default IndustryConnect;
