import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './Components/MainComponent';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <BrowserRouter>
    <div style = {{height:"100%"}}>
      <Main />
    </div>
    </BrowserRouter>
  );
}

export default App;
