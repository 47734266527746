import React, { Component } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const element = <FontAwesomeIcon icon={faTwitter} size="lg" inverse/>
const element1 = <FontAwesomeIcon icon={faLinkedinIn} size="lg" inverse/>
const element2 = <FontAwesomeIcon icon={faFacebookF} size="lg" inverse/>



class Footer extends Component {

  constructor(props) {
        super(props);
        this.invokeTwitter = this.invokeTwitter.bind(this);
        this.invokeLinkedIn = this.invokeLinkedIn.bind(this);
        this.invokeFacebook = this.invokeFacebook.bind(this);
    }


  invokeTwitter() {
      window.open("https://twitter.com/GdcmLtd", "_blank");
  }

  invokeLinkedIn() {
      window.open("https://www.linkedin.com/company/globaldcm/", "_blank");
  }

  invokeFacebook() {
      window.open("https://www.facebook.com/gdcmlogistics/", "_blank");
  }

  render () {
  return (
    <div className="footer">
      <Container fluid>
        <Row></Row>
        <Row>
          <Col>
            <h3><Link className="link" to="/">Global DCM</Link></h3>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col xs={6} md={4}>
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li><Link className="link" to="/home">Home</Link></li>
              <li><Link className="link" to="/aboutus">About Us</Link></li>
              <li><Link className="link" to="/industry">Industry Connect</Link></li>
              <li><Link className="link" to="/locations">Locations and Partners</Link></li>
              <li><Link className="link" to="/contactus">Contact Us</Link></li>
            </ul>
          </Col>

          <Col xs={6} md={4}>
            <h5>Logistics</h5>
            <ul className="list-unstyled">
              <li><Link className="link" to="/Solutions">Logistics Services</Link></li>
              <li><Link className="link" to="/ValueAdded">Value Added Services</Link></li>
              <li><Link className="link" to="/Specialized">Specialized Services</Link></li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col xs={{span: 12}} sm={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 1 }}>
            <div className="text-center">
              <Button variant="outline-secondary" onClick={this.invokeTwitter} className="m-2">{element}</Button>
              <Button variant="outline-secondary" onClick={this.invokeLinkedIn} className="m-2">{element1}</Button>
              <Button variant="outline-secondary" onClick={this.invokeFacebook} className="m-2">{element2}</Button>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <div className="col-auto">
            <p>All content © GDCM 2021.</p>
          </div>
        </Row>
      </Container>
    </div>
  );
  }
}




export default Footer;
