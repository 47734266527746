import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Document } from 'react-pdf';
import {isMobile} from 'react-device-detect';

import samplePDF from '../Files/Ts_Cs_DCM.pdf';

class TC_DCM extends Component {

  constructor(props) {
    super(props);
    this.invokeRedirect = this.invokeRedirect.bind(this);
  }

  invokeRedirect() {
    window.location.replace("http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/Ts_Cs_DCM.0fcaee6f.pdf");
  }

  render() {
    if(isMobile) {
      return (
          <div style={{height:"100%"}}>
            {/*
            {this.invokeRedirect()}
            <Redirect to="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/Ts_Cs_DCM.0fcaee6f.pdf" />
            */}
            <iframe id="pdfviewer" src="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/Ts_Cs_DCM.0fcaee6f.pdf&amp;embedded=true" width="100%" height="100%"></iframe>
          </div>
      );
    }
    return (
      <div style = {{height:"100%"}}>
        {/*<Document
          file="https://globaldcm.com/static/media/Ts_Cs_DCM.0fcaee6f.pdf"
        >
        </Document>*/}
        <object data={samplePDF} type="application/pdf" width="100%" height="100%">
        </object>

        {/*<iframe id="pdfviewer" src="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/Ts_Cs_DCM.0fcaee6f.pdf" frameborder="0" width="100%" height="100%"></iframe>
        */}
      </div>
    );
  }
}

export default TC_DCM;
