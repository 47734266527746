import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Jumbotron } from 'react-bootstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const element = <FontAwesomeIcon icon={faTwitter} size="lg"/>
const element1 = <FontAwesomeIcon icon={faLinkedinIn} size="lg"/>
const element2 = <FontAwesomeIcon icon={faFacebookF} size="lg"/>
const element3 = <FontAwesomeIcon icon={faPhoneAlt} />
const element4 = <FontAwesomeIcon icon={faEnvelope} />


const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);


class ContactUs extends Component {


  constructor(props) {
    super(props);

    this.invokeTwitter = this.invokeTwitter.bind(this);
    this.invokeLinkedIn = this.invokeLinkedIn.bind(this);
    this.invokeFacebook = this.invokeFacebook.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  state = {
    redirect: false,
    mailSent: false,
    error: null
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/thank-you' />
    }
  }

  invokeTwitter() {
      window.open("https://twitter.com/GdcmLtd", "_blank");
  }

  invokeLinkedIn() {
      window.open("https://www.linkedin.com/company/globaldcm/", "_blank");
  }

  invokeFacebook() {
      window.open("https://www.facebook.com/gdcmlogistics/", "_blank");
  }

  handleSubmit(values) {
    console.log("Entered Values are: " + JSON.stringify(values));
    {/*alert("Entered Values are: " + JSON.stringify(values));*/}
    const API_PATH = 'https://globaldcm.com/react-backend/contact/';

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: values
    })
    .then(result => {
      this.setState({
        mailSent: result.data.sent
      })
    })
    .catch(error => this.setState({ error: error.message }));

    //console.log(this.state.mailSent);

    this.setState({redirect: true})
  }

  render() {
    return (
      <Container fluid className="m-0 p-0">
        {this.renderRedirect()}
        <Jumbotron fluid className="contact-jumbo m-0 p-0">
          <Row className="h-100">
            <div className="col-auto h-100 w-100 vertical-center">
              <h1 className="h-100 vertical-center" >Contact Us</h1>
            </div>
          </Row>
        </Jumbotron>

        <Container>
          <Row>
            <h1 className="cover-heading">Contact Info</h1>
          </Row>

          <Row>
            <Col className="col-6">
                <Row>
                  <h3 className="col-12">USA HQ</h3>
                  <hr />
                <div className="col-12">
                  <h5>Global DCM Inc.</h5>
                  <address>
                  30143 Matisse Drive,<br />
                  Rancho Palos Verdes, CA 90275<br />
                  {element3} : +1 310 909 6623<br />
                  {element4} : <a className="link1" href="mailto:
                    infousa@globaldcm.com">infousa@globaldcm.com</a>
                  </address>
                </div>
                </Row>
                <Row>
                  <h3 className="col-12">India</h3>
                  <hr />
                <div className="col-12">
                  <h5>GDCM Logistics Services Pvt. Ltd.</h5>
                  <address>
                  Sahar Classique Bldg., Ground Floor,<br />
                  Sahar Road, Andheri East,<br />
                  Mumbai 400 099<br />
                  {element3} : +91 22 2802 5677<br />
                  {element4} : <a className="link1" href="mailto:
                    infoindia@globaldcm.com">infoindia@globaldcm.com</a>
                  </address>
                </div>
                </Row>
                <Row>
                  <Col xs={{span: 12, offset: 1}}>
                    <Button variant="outline-secondary" onClick={this.invokeTwitter} className="m-2">{element}</Button>
                    <Button variant="outline-secondary" onClick={this.invokeLinkedIn} className="m-2">{element1}</Button>
                    <Button variant="outline-secondary" onClick={this.invokeFacebook} className="m-2">{element2}</Button>
                  </Col>
                </Row>
            </Col>

            <Col className="col-6">
              <Row>
                <Col xs={12}>
                  <h3>Send an Enquiry</h3>
                </Col>
                <Col>
                  <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                    <Form.Group as={Row}>
                      <Col xs={12} lg={3}>
                        <Form.Label htmlFor="firstname">First Name</Form.Label>
                      </Col>
                      <Col xs={12} lg={9}>
                        <Control.text model=".firstname" id="firstname" name="firstname"
                          placeholder="First Name" className="form-control"
                          validators={{
                            required, minLength: minLength(3), maxLength: maxLength(15)
                          }} />
                          <Errors
                          className="text-danger" model=".firstname" show="touched"
                          messages={{
                            required: 'Required ',
                            minLength: 'Must be greater than 2 characters ',
                            maxLength: 'Must be 15 characters or less '
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col xs={12} lg={3}>
                        <Form.Label htmlFor="lastname">Last Name</Form.Label>
                      </Col>
                      <Col lg={9}>
                        <Control.text model=".lastname" id="lastname" name="lastname"
                          placeholder="Last Name" className="form-control"
                          validators={{
                            required, minLength: minLength(3), maxLength: maxLength(15)
                          }} />
                        <Errors
                          className="text-danger" model=".lastname" show="touched"
                          messages={{
                            required: 'Required ',
                            minLength: 'Must be greater than 2 characters ',
                            maxLength: 'Must be 15 ccharacters or less '
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col xs={12} lg={3}>
                        <Form.Label htmlFor="telnum" md={2}>Number</Form.Label>
                      </Col>
                      <Col xs={12} lg={9}>
                        <Control.text model=".telnum" type="tel" id="telnum" name="telnum"
                          placeholder="Tel. Number" className="form-control"
                          validators={{
                            required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                          }} />
                        <Errors
                          className="text-danger" model=".telnum" show="touched"
                          messages={{
                            required: 'Required ',
                            minLength: 'Must be greater than 2 numbers ',
                            maxLength: 'Must be 15 numbers or less ',
                            isNumber: 'Must be a number '
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col xs={12} lg={3}>
                        <Form.Label htmlFor="email" xs={2}>Email</Form.Label>
                      </Col>
                      <Col xs={12} lg={9}>
                        <Control.text model=".email" type="email" id="email" name="email"
                          placeholder="Enter email" className="form-control"
                          validators={{
                            required, validEmail
                          }} />
                        <Errors
                          className="text-danger" model=".email" show="touched"
                          messages={{
                            required: 'Required ',
                            validEmail: 'Invalid Email Address '
                          }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Col xs={12}>
                        <Form.Label htmlFor="message" md={2}>Message</Form.Label>
                      </Col>
                      <Col xs={12}>
                        <Control.textarea model=".message" type="textarea" id="message" name="message"
                          rows={8} className="form-control" />
                      </Col>
                    </Form.Group>

                    <Form.Group>
                      <Button className="col-12" variant="light" type="submit">
                        Send Enquiry
                      </Button>
                    </Form.Group>
                  </LocalForm>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default ContactUs;
