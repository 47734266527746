import React from 'react';
import { Card, Row, Col, Container, Jumbotron, CardDeck } from 'react-bootstrap';

import multiM from '../Images/slider-banner.jpg';
import Dcm from '../Images/slider-banner1.jpg';
import Spc from '../Images/slider-banner3.jpg';

import oceanFreight from '../Images/Picture1.jpg';
import airFreight from '../Images/5f60ca4501dfc.jpg';
import groundFreight from '../Images/shutterstock_537088753-reduced-jpg-60.jpg';
import railFreight from '../Images/5f60c73f5e491.jpg';
import overallFleetManagement from '../Images/5f60cd0e5e70c.jpg';
import cargoInsurance from '../Images/5f60c89df40ac.jpg';


function Solutions() {
  return (
    <Container fluid className="p-0 m-0">
      <Jumbotron fluid className="logistics-jumbo">
        <Row className="justify-content-center">
          <div className="col-auto">
            <h1>Logistics Services</h1>
          </div>
        </Row>
      </Jumbotron>

      <Row className="w-100 py-0 pl-5 pr-0 m-0">
        <Col xs={12} sm={12} lg={6} className="solutions_abt m-0 pl-5">
          <h1>Multi-M Services</h1>
          <h5>Multi Modal Services</h5>
          <p>We handle all forms of multi-modal transportation services and boast to have
            an excellent global network with presence in every major city.
          </p>
          <ul>
            <li>Planning and executing air & sea imports / exports.</li>
            <li>Assistance in getting customs clearance.</li>
            <li>Have Global Agent Network with presence in every major city around the world.</li>
            <li>Have implemented Latest Systems and operating procedures to provide the best services in the industry.</li>
          </ul>

        </Col>
        <Col xs={0} sm={0} md={0} lg={6} className="m-0 p-0 habt" id="multimodal">
          <img
            className="d-block h-100 w-100 habt"
            src={multiM}
            alt="Multi M Services"
          />
        </Col>
      </Row>

      <Row className="hom-car mx-0 py-5 habt grey-background">
        <Row className="hom-car mx-0">
          <h3>How can Global DCM Logistics can support your supply chain?</h3>
          <p>From moving a shipping container via our ocean freight service (FCL or LCL)
          or an international air freight shipment with a time sensitive transit time through
          to a complex customized contract logistics solution, we have the logistics management experience
          and supply chain expertise to help your company with the details so you can focus on your customers.
          </p>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card >
              <Card.Img variant="top" src={oceanFreight} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Ocean Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={airFreight} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Air Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={railFreight} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Rail Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
          <div className="p-2">
          <Card>
            <Card.Img variant="top" src={groundFreight} height={180} width={300}/>
            <Card.Body className="grey-background">
              <Card.Text>Ground Trucking</Card.Text>
            </Card.Body>
          </Card>
          </div>
          </Col>

          <Col md={4} lg={3} sm ={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={cargoInsurance} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Cargo Insurance</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={overallFleetManagement} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Overall Fleet Management</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="hom-car m-0">
        <Row className="mt-5">
          <h1 className="w-100">Value Added Services</h1>
          </Row>
        <Row className="justify-content-md-center mb-5">
          <CardDeck>
            <Card>
              <Card.Img variant="top" src={Dcm} />
              <Card.Body>
                <Card.Title>DCM Services</Card.Title>
                <Card.Text>
                  GDCM, helps in identifying customers demand, planning your supply chain and designing
                  inbound and outbound customer tailored services to fulﬁll manufacturing, assembly
                  and Production requirements
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Img variant="top" src={Spc} />
              <Card.Body>
                <Card.Title>SPC Services</Card.Title>
                <Card.Text>
                  Over the period of time we have built strategic alliance with specialized
                  small parcel  courier companies across the globe and can offer inbound and
                  outbound urgent delivery  services on small parcels.
                </Card.Text>
              </Card.Body>
            </Card>

          </CardDeck>
        </Row>
      </Row>

    </Container>
  );
}

export default Solutions;
