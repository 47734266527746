import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Jumbotron } from 'react-bootstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Redirect } from 'react-router-dom';

const required = (val) => val && val.length;
const maxLength = (len) => (val) => !(val) || (val.length <= len);
const minLength = (len) => (val) => val && (val.length >= len);
const isNumber = (val) => !isNaN(Number(val));
const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class Quote extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
  }

  state = {
    redirect: false,
    mailSent: false,
    error: null
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/thank-you' />
    }
  }

  handleSubmit(values) {
    console.log("Entered Values are: " + JSON.stringify(values));
    {/*alert("Entered Values are: " + JSON.stringify(values));*/}

    const API_PATH = 'https://globaldcm.com/react-backend/quote/';

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: values
    })
    .then(result => {
      this.setState({
        mailSent: result.data.sent
      })
    })
    .catch(error => this.setState({ error: error.message }));

    //console.log(this.state.mailSent);
    this.setState({redirect: true})
  }

  render() {
    return (
      <Container fluid className="m-0 p-0">
        {this.renderRedirect()}
        <Jumbotron fluid className="quote-jumbo">
          <Row className="justify-content-center">
            <div className="col-auto">
              <h1>Request A Quote</h1>
            </div>
          </Row>
        </Jumbotron>

        <Container>
        <div className="quote">
          <Row>
            <h3>Reach out to our global logistics experts for advice. </h3>
            <p>Our global logistics team are available to support your logistics and freight operations,
              reach out with your inquiry below and we will connect you with a representative to discuss your logistics challenges,
              and determine the best solution to assist you!
              We can provide you with a freight quote, warehouse solution, logistics support, and much more.</p>
          </Row>
          <Row>

            <div className="col-12">
              <LocalForm onSubmit={(values) => this.handleSubmit(values)}>
                <Form.Group as={Row}>
                  <Col xs={12}>
                    <Form.Label htmlFor="fullname" md={12}><strong>Full Name</strong></Form.Label>
                  </Col>
                  <Col md={12}>
                    <Control.text model=".fullname" id="fullname" name="fullname"
                      placeholder="Full Name" className="form-control"
                      validators={{
                        required, minLength: minLength(3), maxLength: maxLength(20)
                      }} />
                    <Errors
                      className="text-danger" model=".fullname" show="touched"
                      messages={{
                        required: 'Required ',
                        minLength: 'Must be greater than 2 characters ',
                        maxLength: 'Must be 20 characters or less '
                      }}
                    />
                  </Col>
                </Form.Group>
                <Row className="form-group">
                  <Col xs={12}>
                    <Form.Label htmlFor="companyname" md={12}><strong>Company Name</strong></Form.Label>
                  </Col>
                  <Col md={12}>
                    <Control.text model=".companyname" id="companyname" name="companyname"
                      placeholder="Company Name" className="form-control"
                      validators={{
                        required, minLength: minLength(3), maxLength: maxLength(20)
                      }} />
                    <Errors
                      className="text-danger" model=".companyname" show="touched"
                      messages={{
                        required: 'Required ',
                        minLength: 'Must be greater than 2 characters ',
                        maxLength: 'Must be 20 characters or less '
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col xs={12}>
                    <Form.Label htmlFor="telnum" md={12}><strong>Contact Tel.</strong></Form.Label>
                  </Col>
                  <Col md={12}>
                    <Control.text model=".telnum" type="tel" id="telnum" name="telnum"
                      placeholder="Tel. Number" className="form-control"
                      validators={{
                        required, minLength: minLength(3), maxLength: maxLength(15), isNumber
                      }} />
                    <Errors
                      className="text-danger" model=".telnum" show="touched"
                      messages={{
                        required: 'Required ',
                        minLength: 'Must be greater than 2 numbers ',
                        maxLength: 'Must be 15 numbers or less ',
                        isNumber: 'Must be a number '
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col xs={12}>
                    <Form.Label htmlFor="email" md={12}><strong>Email</strong></Form.Label>
                  </Col>
                  <Col md={12}>
                    <Control.text model=".email" type="email" id="email" name="email"
                      placeholder="Email" className="form-control"
                      validators={{
                        required, validEmail
                      }} />
                    <Errors
                      className="text-danger" model=".email" show="touched"
                      messages={{
                        required: 'Required ',
                        validEmail: 'Invalid Email Address'
                      }}
                    />
                  </Col>
                </Row>
                <Form.Row className="w-100">
                  <Form.Group as={Col} controlId="formOriginCity">
                    <Form.Label htmlFor="origincity" md={12}><strong>Origin City</strong></Form.Label>
                    <Control.text model=".origincity" id="origincity" name="origincity"
                      placeholder="Origin City" className="form-control"
                      validators={{
                        required, minLength: minLength(3), maxLength: maxLength(20)
                      }} />
                    <Errors
                      className="text-danger" model=".origincity" show="touched"
                      messages={{
                        required: 'Required ',
                        minLength: 'Must be greater than 2 characters',
                        maxLength: 'Must be 20 characters or less'
                      }}
                    />
                  </Form.Group>


                  <Form.Group as={Col} controlId="formDestinationCity">
                    <Form.Label htmlFor="destinationcity" md={12}><strong>Destination City</strong></Form.Label>
                    <Control.text model=".destinationcity" id="destinationcity" name="destinationcity"
                      placeholder="Destination City" className="form-control"
                      validators={{
                        required, minLength: minLength(3), maxLength: maxLength(20)
                      }} />
                    <Errors
                      className="text-danger" model=".destinationcity" show="touched"
                      messages={{
                        required: 'Required',
                        minLength: 'Must be greater than 2 characters',
                        maxLength: 'Must be 20 characters or less'
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Row className="form-group">
                  <Col xs={12} lg={3}>
                    <Form.Label htmlFor="message" md={2}><strong>Comments</strong></Form.Label>
                  </Col>
                  <Col md={12}>
                    <Control.textarea model=".message" type="textarea" id="message" name="message"
                      rows={12} className="form-control" />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col>
                    <Button type="submit" className="col-6" variant="secondary">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </LocalForm>
            </div>
          </Row>
        </div>
        </Container>
      </Container>
    );
  }
}

export default Quote;
