import React from 'react';
import { Row, Container, Button, Col, Card } from 'react-bootstrap';

import mainPic from '../Images/container-1638068-modified-reduced-60.jpg';
import mainPic2 from '../Images/about-us-banner.jpg';

import oceanFreight from '../Images/Picture1.jpg';
import airFreight from '../Images/5f60ca4501dfc.jpg';
import groundFreight from '../Images/shutterstock_537088753-reduced-jpg-60.jpg';
import railFreight from '../Images/5f60c73f5e491.jpg';
import overallFleetManagement from '../Images/5f60cd0e5e70c.jpg';
import cargoInsurance from '../Images/5f60c89df40ac.jpg';

import logo1 from '../Images/customers/waaree.png';
import logo2 from '../Images/customers/Pavers-England-Logo.jpg';
import logo3 from '../Images/customers/semikron-logo.png';
import logo4 from '../Images/customers/unique air express logo.jpg';
import logo5 from '../Images/customers/biomex-instruments-private-limited-120x120.png';
import logo6 from '../Images/customers/gf inc.png';
import logo7 from '../Images/customers/vin-semiconductors-private-limited-logo-120x120.png';

import logo8 from '../Images/customers/loreal-logo-png-5323615.png';
import logo9 from '../Images/customers/bhatia_shipping_logo.gif';
import logo10 from '../Images/customers/elementto logo.jpg';
import logo11 from '../Images/customers/Kaymo_Logo-dk.jpg';
import logo12 from '../Images/customers/hamilton logo.png';
import logo13 from '../Images/customers/cocacola_logo_PNG10.png';
import logo14 from '../Images/customers/vital logo.png';

import logo15 from '../Images/customers/htr_logo.jpg';
import logo16 from '../Images/customers/TITAN-ASIA-VARIANTE-ombre.png';
import logo17 from '../Images/customers/godrej_logo.gif';
import logo18 from '../Images/customers/biopac logo.jpg';
import logo19 from '../Images/customers/dynamic-logo.jpg';
import logo20 from '../Images/customers/tgls-logo.jpg';

import curveTop from '../Images/curve-top-modified-left.png';
import curveBottom from '../Images/curve-bottom-modified-right.png';


function Home() {
  return (
    <Container fluid className="p-0">
      <Row className="mx-0 p-0">
        <div className="main">
          <img
              className="ImageMain"
              height={700}
              width ={2000}
              src={mainPic}
              alt="First slide"
          />
          <div className="centered">
            <h1>World class customised</h1>
            <h1> logistics services</h1>
            <p>We meet your specific needs, handle all your queries and manage all your processes and
            movements related to freight and shipment services</p>
            <br />
            <Button className="col-sm-4 col-6" variant="outline-light" href="/Solutions">
              Our Services
            </Button>
          </div>
        </div>
      </Row>

      <Row className="hom-car mx-0 py-5 habt">
        <Row className="hom-car mx-0">
          <h3>How can Global DCM Logistics can support your supply chain?</h3>
          <p>From moving a shipping container via our ocean freight service (FCL or LCL)
          or an international air freight shipment with a time sensitive transit time through
          to a complex customized contract logistics solution, we have the logistics management experience
          and supply chain expertise to help your company with the details so you can focus on your customers.
          </p>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={oceanFreight} height={180} width={300}/>
              <Card.Body>
                <Card.Text>Ocean Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={airFreight} height={180} width={300}/>
              <Card.Body>
                <Card.Text>Air Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={railFreight} height={180} width={300}/>
              <Card.Body>
                <Card.Text>Rail Freight</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
          <div className="p-2">
          <Card>
            <Card.Img variant="top" src={groundFreight} height={180} width={300}/>
            <Card.Body>
              <Card.Text>Ground Trucking</Card.Text>
            </Card.Body>
          </Card>
          </div>
          </Col>

          <Col md={4} lg={3} sm ={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={cargoInsurance} height={180} width={300}/>
              <Card.Body>
                <Card.Text>Cargo Insurance</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={overallFleetManagement} height={180} width={300}/>
              <Card.Body>
                <Card.Text>Overall Fleet Management</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="mx-0 p-0 negative-margin">
        <Row className="w-100 m-0 p-0 ">
          <img src={curveTop} alt="CurveTop"/>
        </Row>
        <div className="filtered">
          <img
              className="d-block w-100"
              height={700}
              src={mainPic2}
              alt="Globe"
          />
          <div className="centered">
            <h1>Areas We Serve</h1>
            <br />
            <p>We ship cargo / freight globally by air and sea to / from 193 countries
            in 805 cities and ports around the world.</p>
            <br />
            <Button className="col-sm-6 col-md-4 col-8" variant="outline-light" href="/quote">
              Request a Quote
            </Button>
          </div>
        </div>
        <Row className="w-100 m-0 p-0 ">
          <img src={curveBottom} alt="curveBottom"/>
        </Row>
      </Row>


      <Row className="w-100 m-0 locnpar" >
        <h1>GDCM Value Addition</h1>
        <ul className="list-unstyled" style={{"text-align":"justify"}}>
          <li><strong>Reliable Service - </strong>Proven service with long term client
          base and success histories</li>
          <hr />
          <li><strong>Cost competitive - </strong>Flexible and proactive costing approach
          with a menu driven cost model designed for immediate quotation turnaround</li>
          <hr />
          <li><strong>Better Communications – </strong>Transparent & seamless communication
          channel with client specific help desk</li>
          <hr />
          <li><strong>Quality control – </strong>Floor management with emphasis on
          service delivery, damage limitation and document control </li>
          <hr />
          <li><strong>Time sensitivity – </strong>Rapid turnaround of containers and
          trailers with cradle to grave tracking of each within the chain from pickup to return</li>
        </ul>
      </Row>








    </Container>
  );
}

export default Home;
