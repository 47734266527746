import React from 'react';
import { Card, Row, Col, Container, Jumbotron } from 'react-bootstrap';

import fpl from '../Images/slider-banner2.jpg';

import telescopic from '../Images/5f85e6b96e158.jpg';
import steelStake from '../Images/Picture3.jpg';
import knuckleBoom from '../Images/shutterstock_451539856-reduced-50.jpg';
import outOfGauge from '../Images/shutterstock_1712779543-reduced-60.jpg';

function SpecializedServices() {
  return (
    <Container fluid className="p-0 m-0">
      <Jumbotron fluid className="logistics-jumbo">
        <Row className="justify-content-center">
          <div className="col-auto">
            <h1>Specialized Services</h1>
          </div>
        </Row>
      </Jumbotron>

      <Row className="w-100 py-0 pl-5 pr-0 m-0">
        <Col xs={12} sm={12} lg={6} className="solutions_abt m-0 pl-5">
          <h1>4PL Services</h1>
          <h5>Fourth Party Logistics</h5>
          <p>We take pride in saying that we have partnerships with the best companies
          across the  globe in the business of transportation, warehousing, clearance
          and Insurance to pro-  vide you with the optimum logistic services that money can buy.
          </p>
          <ul>
            <li>Project cargo logistics </li>
            <li>Cool chain management (Temperature controlled Supply chain)</li>
            <li>Identiﬁcation of Air and vessel chartering services</li>
            <li>Identiﬁcation and contracting with warehouses across the globe (Warehouse Management)</li>
            <li>Planning and executing Pan- India Distribution</li>
            <li>Planning and executing part load/ full load Transportation</li>
            <li>Implementing business automation of tasks, workﬂows and processes</li>
          </ul>

        </Col>
        <Col xs={0} sm={0} md={0} lg={6} className="m-0 p-0 habt" id="multimodal">
          <img
            className="d-block h-100 w-100 habt"
            src={fpl}
            alt="Multi M Services"
          />
        </Col>
      </Row>

      <Row className="hom-car mx-0 py-5 habt grey-background">
        <Row className="mx-0">
          <h3>Trucking Solutions</h3>
          <p>Global DCM provides all types of trucking solutions to move any type of cargo.
          We have the logistics management experience and supply chain expertise to help
          your company with the details so you can focus on your customers.
          </p>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card >
              <Card.Img variant="top" src={telescopic} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Telescopic Booms</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={steelStake} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Steel Stake Body Trucks</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
            <div className="p-2">
            <Card>
              <Card.Img variant="top" src={knuckleBoom} height={180} width={300}/>
              <Card.Body className="grey-background">
                <Card.Text>Flatbed Services</Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col md={4} lg={3} sm={6}>
          <div className="p-2">
          <Card>
            <Card.Img variant="top" src={outOfGauge} height={180} width={300}/>
            <Card.Body className="grey-background">
              <Card.Text>Reliable Drivers</Card.Text>
            </Card.Body>
          </Card>
          </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default SpecializedServices;
