import React, { Component } from 'react';
import { Row, Col, Container, Jumbotron } from 'react-bootstrap';

import thank from '../Images/exp/5f6a45aeb72e8.jpg';

class Thank extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Container fluid className="m-0 p-0 w-100">
        <Jumbotron fluid className=" m-0 thank-jumbo">
          <Row className="h-100">
            <div className="col-auto h-100 w-100 vertical-center">
              <h1 className="h-100 vertical-center">We are getting ready to assist you</h1>
            </div>
          </Row>
        </Jumbotron>
        <Row className="m-0 py-0 grey-background">
          <Col className="mx-auto vertical-center">
          <img
            className="d-block h-100 pt-5 pb-0 w-75 habt"
            src={thank}
            alt="Thank You"
            style={{'-webkit-filter': 'grayscale(50%)',
            'filter': 'grayscale(50%)', 'opacity': '1'}}
          />
          </Col>
        </Row>
        <Row className="m-0 pt-3 pb-4 locnpar grey-background success">
          <p className="col-12" style={{'font-size':'3em', 'font-weight': 'bold', 'font-family':'monospace', 'line-height':'2em', 'color':'#2C3753'}}>Success!</p>
          <p className="col-12" style={{'font-size':'1.75em', 'font-family':'inherit', 'line-height':'1.5em', 'color':'#2C3753'}}>We appreciate you taking the time to contact us.</p>
          <p className="col-12" style={{'font-size':'1.75em', 'font-family':'inherit', 'line-height':'1.5em', 'color':'#2C3753'}}>Our experts are analyzing your inquiry. We will contact you shortly.</p>
          <p className="col-12" style={{'font-size':'1.2em', 'font-family':'inherit', 'line-height':'1.5em'}}>For urgent enquiries please call us on one of the telephone numbers below.</p>
        </Row>
      </Container>
    );
  }
}

export default Thank;
