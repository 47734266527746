import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving, faPlaneDeparture, faTrain, faShip } from '@fortawesome/free-solid-svg-icons'
import { faUniversity, faCogs } from '@fortawesome/free-solid-svg-icons'


import companyPic from '../Images/5f6110f228564-modified.jpg';
import teamPic from '../Images/5f5e43885a069.jpg';
import worldPic from '../Images/5f6113f3f2bcb.jpg';
import truckPic from '../Images/shutterstock_451539856-reduced-50.jpg';
import personPic from '../Images/5f61296cd53d0.jpg';

import rajmorPic from '../Images/people/rajmor.jpg';
import garyPic from '../Images/people/gary.jpg';

const element1 = <FontAwesomeIcon icon={faTruckMoving} size="lg" inverse/>
const element2 = <FontAwesomeIcon icon={faPlaneDeparture} size="lg" inverse/>
const element3 = <FontAwesomeIcon icon={faTrain} size="lg" inverse/>
const element4 = <FontAwesomeIcon icon={faShip} size="lg" inverse/>

const element5 = <FontAwesomeIcon icon={faUniversity} size="lg" inverse/>
const element6 = <FontAwesomeIcon icon={faCogs} size="lg" inverse/>


function About() {
  return (
    <Container fluid className="p-0 m-0">

      <Row className="mx-0 p-0 ">
        <div className="main wide-pic">
          <img
              className="d-block w-100"
              height={700}
              src={companyPic}
              alt="First slide"
          />
          <div className="centered-left">
            <h1>About Global DCM</h1>
            <br />
            <p>"Our mission is to create the most efficient transportation network in North America."</p>
          </div>
        </div>
      </Row>

      <Row className="p-0 m-0">
        <Col xs={12} md={6} className="m-0 p-0">
          <img
            className="d-block h-100 w-100"
            src={teamPic}
            alt="First slide"
          />
        </Col>

        <Col xs={12} md={6} className="habt grey-background">
          <h1>Who Are We</h1>
          <p>GDCM is an international freight operator and services  customers in both imports and exports,
          airfreight and sea	freight	shipments,	transhipment	cargo,  door-to-door delivery, Customs
          clearance, consolidation,  project cargo and breakbulk service, pick-up, and  delivery.
          </p>
        </Col>
      </Row>

      <Row className="p-0 m-0">
        <Col xs={12} md={6} className="habt">
          <h1>Where We Operate</h1>
          <p>GDCM is an international freight operator and services  customers in both imports and exports,
          airfreight and sea	freight	shipments,	transhipment	cargo,  door-to-door delivery, Customs
          clearance, consolidation,  project cargo and breakbulk service, pick-up, and  delivery.
          </p>
        </Col>

        <Col xs={12} md={6} className="m-0 p-0">
          <img
            className="d-block w-100 h-100"
            src={worldPic}
            alt="First slide"
          />
        </Col>
      </Row>

      <Row className="mx-0 p-0 ">
        <div className="main filtered">
          <img
              className="d-block w-100"
              height={700}
              src={truckPic}
              alt="First slide"
          />
          <div className="text-center centered solutions_logo">
            <h1>Our Solutions</h1>
            <br />
            <Row className="text-center w-100 p-0 m-0">
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element1}</span>
                  <p>Ground Trucking</p>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element2}</span>
                  <p>Air Freight</p>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element3}</span>
                  <p>Rail Freight</p>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element4}</span>
                  <p>Ship Freight</p>
                </div>
              </Col>
            </Row>

            <Row className="text-center w-100 p-0 m-0 justify-content-center">
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element5}</span>
                  <p>Cargo Insurance</p>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="w-100 m-3">
                  <span>{element6}</span>
                  <p>Overall Fleet Management</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>

      <Row className="w-100 h-100 m-0 habt">
        <Row>
          <Col xs={12}>
            <h1>Meet the Team</h1>
          </Col>
        </Row>

        <Row clasName="habt">
          <Col xs={6} sm={4}>
            <img src={rajmorPic} alt="" className="about_person" />
            <strong>Rajmor Dias</strong>
            <p>President & CEO</p>
          </Col>

          <Col xs={6} sm={4}>
            <img src={personPic} alt="" className="about_person" />
            <strong>Johnson Pinto</strong>
            <p>Operations Manager</p>
          </Col>

          <Col xs={6} sm={4}>
            <img src={personPic} alt="" className="about_person" />
            <strong>Navin Tambe</strong>
            <p>Customer Service</p>
          </Col>

          <Col xs={6} sm={4}>
            <img src={personPic} alt="" className="about_person" />
            <strong>Deepak Gurav</strong>
            <p>IT Infrastructure</p>
          </Col>
        </Row>
      </Row>

    </Container>
  );
}

export default About;
