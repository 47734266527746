import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Home from './HomeComponent';
import Solutions from './SolutionsComponent';
import ValueAddedServices from './ValueAddedServicesComponent';
import SpecializedServices from './SpecializedServicesComponent';
import About from './AboutComponent';
import LocationsNPartners from './LocationsNPartnersComponent';
import IndustryConnect from './IndustryConnectComponent';
import ContactUs from './ContactUsComponent';
import Quote from './QuoteComponent';
import Thank from './ThankComponent';
import OBLReverse from './OBLReverse';
import TC_DCM from './TC_DCM';
import ScrollToTop from './ScrollToTop';

class Main extends Component {

  render() {
    return (
        <div className="p-0 m-0" style = {{height:"100%"}}>
          <ScrollToTop />
          <Switch>

            <Route exact path="/documents/OBL-Reverse.pdf" component={OBLReverse} />

            <Route exact path="/documents/Ts_Cs_DCM.pdf" component={TC_DCM} />

            <Route exact path="/" render={() =>
              <Fragment>
                <Header />
                <Home />
                <Footer />
              </Fragment>
            } />
            <Route path="/Solutions" render={() =>
              <Fragment>
                <Header />
                <Solutions />
                <Footer />
              </Fragment>
            } />
            <Route path="/ValueAdded" render={() =>
              <Fragment>
                <Header />
                <ValueAddedServices />
                <Footer />
              </Fragment>
            } />
            <Route path="/Specialized" render={() =>
              <Fragment>
                <Header />
                <SpecializedServices />
                <Footer />
              </Fragment>
            } />
            <Route path="/aboutus" render={() =>
              <Fragment>
                <Header />
                <About />
                <Footer />
              </Fragment>
            } />
            <Route path="/locations" render={() =>
              <Fragment>
                <Header />
                <LocationsNPartners />
                <Footer />
              </Fragment>
            } />
            <Route path="/industry" render={() =>
              <Fragment>
                <Header />
                <IndustryConnect />
                <Footer />
              </Fragment>
            } />
            <Route path="/contactus" render={() =>
              <Fragment>
                <Header />
                <ContactUs />
                <Footer />
              </Fragment>
            } />
            <Route path="/quote" render={() =>
              <Fragment>
                <Header />
                <Quote />
                <Footer />
              </Fragment>
            } />
            <Route path="/thank-you" render={() =>
              <Fragment>
                <Header />
                <Thank />
                <Footer />
              </Fragment>
            } />
            <Redirect to="/" />
          </Switch>

        </div>
    );

  }
}

export default Main;



// //if (exact path=/documents/OBL-Reverse.pdf) {
//   <OBLReverse />
//
// }
// else {
// <div>
