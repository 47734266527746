import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Document } from 'react-pdf';
import {isMobile} from 'react-device-detect';

import samplePDF from '../Files/OBL-Reverse.pdf';

class OBLReverse extends Component  {

  constructor(props) {
    super(props);
    this.invokeRedirect = this.invokeRedirect.bind(this);
  }

  invokeRedirect() {
    window.location.replace("http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/OBL-Reverse.d7a197b4.pdf");
  }

  render() {
    if(isMobile) {
        return (
          <div style={{height:"100%"}}>
            {/*
            {this.invokeRedirect()}
            <Redirect to="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/OBL-Reverse.d7a197b4.pdf" />

            */}
            <iframe id="pdfviewer" src="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/OBL-Reverse.d7a197b4.pdf&amp;embedded=true" width="100%" height="100%"></iframe>
            </div>
        );
    }
    return (
      <div style = {{height:"100%"}}>
        {/*
        <Document
          file="https://globaldcm.com/static/media/OBL-Reverse.d7a197b4.pdf"
        >
        </Document>
        */}
        <object data={samplePDF} type="application/pdf" width="100%" height="100%">
        </object>

        {/*<iframe id="pdfviewer" src="http://docs.google.com/gview?embedded=true&url=https://globaldcm.com/static/media/OBL-Reverse.d7a197b4.pdf" frameborder="0" width="100%" height="100%"></iframe>
        */}
      </div>
    );
  }
}

export default OBLReverse;
