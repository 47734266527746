import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';

import dcm from '../Images/slider-banner1.jpg';
import spc from '../Images/slider-banner3.jpg';

function ValueAddedServices() {
  return (
    <Container fluid className="m-0 p-0 w-100">
      <Jumbotron fluid className=" m-0 logistics-jumbo">
        <Row className="justify-content-center">
          <div className="col-auto">
            <h1>Value Added Services</h1>
          </div>
        </Row>
      </Jumbotron>

      <Row className="w-100 py-0 pl-5 pr-0 m-0 grey-background">
        <Col xs={12} sm={12} lg={6} className="solutions_abt m-0 pl-5">
          <h1>SPC Services</h1>
          <h5>Small Parcel Courier</h5>
          <p>Over the period of time we have built strategic alliance with specialized
          small parcel  courier companies across the globe and can offer inbound and
          outbound urgent delivery  services on small parcels
          </p>
          <ul>
            <li>Ties with small parcel courier companies</li>
            <li>Has a global network with presence in 190 countries and 815 cities around the globe</li>
            <li>Specialize in inbound / =outbound urgent delivery on small parcels</li>
            <li>We offer order fulﬁllment and last- mile deliveries upto proof of delivery (POD)</li>
          </ul>

        </Col>
        <Col xs={0} sm={0} md={0} lg={6} className="m-0 p-0 habt" id="multimodal">
          <img
            className="d-block h-100 w-100 habt"
            src={spc}
            alt="SPC Services"
          />
        </Col>
      </Row>

      <Row className="w-100 py-0 pl-5 pr-0 m-0 grey-background">
        <Col xs={12} sm={12} lg={6} className="solutions_abt m-0 pl-5">
          <h1>DCM Services</h1>
          <h5>Demand Chain Management</h5>
          <p>GDCM, helps in identifying customers demand, planning your supply chain
          and designing inbound and outbound customer tailored services to fulﬁll
          manufacturing, assembly  and Production requirements
          </p>
          <ul>
            <li>Design Inbound and outbound customer requirements</li>
            <li>Provide insights to customer demand and supply</li>
            <li>Provide services that assist in planning of manufacturing, assembly and production</li>
            <li>Has set up decision support systems (DSS)in planning the supply chain</li>
            <li>Provide SGS inspection and certiﬁcations services</li>
          </ul>

        </Col>
        <Col xs={0} sm={0} md={0} lg={6} className="m-0 p-0 habt" id="multimodal">
          <img
            className="d-block h-100 w-100 habt"
            src={dcm}
            alt="DCM Services"
          />
        </Col>
      </Row>

    </Container>
  );
}

export default ValueAddedServices;
