import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';

import challenges from '../Images/mckinsey challenges.svgz';


function LocationsNPartners() {
  return (
    <Container fluid className="m-0 p-0 w-100" /*style={{backgroundImage:`url(${backPic})`, "background-size": "cover",
    "background-repeat": "no-repeat"}}*/>
      <Jumbotron fluid className=" m-0 location-jumbo">
        <Row className="h-100">
          <div className="col-auto h-100 w-100 vertical-center">
            <h1 className="h-100 vertical-center">Locations and Partners</h1>
          </div>
        </Row>
      </Jumbotron>

      <Row className="w-100 m-0 locnpar" >
        <h1>GDCM Value Addition</h1>
        <ul className="list-unstyled" style={{"text-align":"justify"}}>
          <li><strong>Reliable Service - </strong>Proven service with long term client
          base and success histories</li>
          <hr />
          <li><strong>Cost competitive - </strong>Flexible and proactive costing approach
          with a menu driven cost model designed for immediate quotation turnaround</li>
          <hr />
          <li><strong>Better Communications – </strong>Transparent & seamless communication
          channel with client specific help desk</li>
          <hr />
          <li><strong>Quality control – </strong>Floor management with emphasis on
          service delivery, damage limitation and document control </li>
          <hr />
          <li><strong>Time sensitivity – </strong>Rapid turnaround of containers and
          trailers with cradle to grave tracking of each within the chain from pickup to return</li>
          <hr />
          <li><strong>Options – </strong>Upstream and downstream options for clients – should
          they experience delays or challenges GDCM can arrange “relief” outcomes at
          marginal cost where applicable – overflow strategy</li>
          <hr />
          <li><strong>Capacity planning – </strong>Matching inbound to outbound cube,
          interfacing with warehouse and transportation – system ability</li>
          <hr />
          <li><strong>Container management – </strong>Managing containers from pick up
          through return to steam ship line custody – demurrage control</li>
          <hr />
          <li><strong>Drayage relations – </strong>Long term drayage relations in place
          with reputable operators</li>
        </ul>
      </Row>

      <Row className="w-100 m-0 p-0 grey-background" >
        <Row className="locnpar py-5">
          <Col xs={12} sm={12}>
            <h1>Supply Chain Challenges</h1>
          </Col>
        </Row>
        <Row className="w-100 m-0 p-0">
          <Col clasName="w-100 my-0 m-0 p-0">
            <img
                className="d-block w-100 logo mx-auto my-1"
                src={challenges}
                alt="First slide"
            />
          </Col>
        </Row>
      </Row>

      <Row className="w-100 m-0 locnpar ">
        <h1>Global Concerns (Views)</h1>
        <p style={{"text-align":"left", "padding-top":"3%"}}>
        “Among the challenges facing today’s supply chain are many that link directly to monetization. Market volatility, economic contractions and modest recovery cycles affect the way companies manage distribution, manufacturing, invoicing and materials sourcing. Expansion into new markets introduces complex taxation, invoicing and localization burdens. And dispersed market segments demand different pricing models and services. With so many critical functions in flux, enterprises need to optimize their supply chains simply to remain competitive.”
        - Scott Swartz is Founder and CEO, MetraTech Corporation
        </p>
        <p style={{"text-align":"right", "padding-top":"3%"}}>
        Businesses with optimal supply chains have 15% lower supply chain costs, less than 50% of the inventory holdings and cash-to-cash cycles at least three times faster than those not focused on supply chain optimisation.
        -- PWC survey
        </p>
        <p style={{"text-align":"left", "padding-top":"3%"}}>
        Luxury car manufacturer, BMW, recently suffered production stoppage of its 3-Series sedan in Germany, China, and South Africa, due to Bosch’s failure to supply the necessary steering gears. The aforementioned incident highlights the importance of supply chain management.
        -- Andy Uhlenberg
        </p>
      </Row>

      <Row className="w-100 m-0 locnpar grey-background">
        <h1>GDCM Radar</h1>
        <h6 style={{"text-align":"left", "padding-top":"3%"}}>
          GDCM plans to use its mature processes and proven expertise in delivering
          state of the art solutions based on the 4PL model to augment GRP’s business
          strengths. It has on its radar, objectives to be achieved such as:
        </h6>
        <ul style={{"text-align":"justify", "padding-top":"2%"}}>
          <li>Allow GRP to focus on core-business of manufacturing / marketing / customer service </li>
          <li>Leverage shared infrastructure and administrative synergies leading to better utilisation of assets</li>
          <li>Smart volume management to justify business volatility  </li>
          <li>Provide single point of responsibility to deliver agreed service levels like quality of products till
                retail point and state & quantity of goods till consumption </li>
          <li>Lessen the total logistics cost (by improving TAT that impacts inventory)</li>
          <li>Better GRP’s inventory management, order management </li>
        </ul>
      </Row>
    </Container>
  );
}

export default LocationsNPartners;
