import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import Logo from '../Images/logo.jpg';
import {NavLink, Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <Navbar bg="light" className="navbar-custom" expand="lg" sticky="top" style={{display : 'flex'}}> 
        <Navbar.Brand >
          <NavLink tag={Link} to = "/">
          <img src={Logo} height="30" width="70" alt="GlobalDCM" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          <NavLink tag = {Link} to="/aboutus" style={{ textDecoration: 'none' ,marginTop : 5.9,paddingRight: 10}}>About Us</NavLink>
            <NavDropdown title="Logistics" id="basic-nav-dropdown" style={{ textDecoration: 'none' ,marginTop : -2.9,paddingRight: 10}}>
              <NavDropdown.Item ><NavLink tag = {Link} to = "/Solutions"style={{ textDecoration: 'none'}} >Logistics Solutions</NavLink></NavDropdown.Item>
              <NavDropdown.Item ><NavLink tag = {Link} to = "/ValueAdded"style={{ textDecoration: 'none'}} >Value Added Services</NavLink></NavDropdown.Item>
              <NavDropdown.Item ><NavLink tag = {Link} to = "/Specialized"style={{ textDecoration: 'none'}} >Specialized Services</NavLink></NavDropdown.Item>
            </NavDropdown>
            <NavLink tag = {Link} to="/industry" style={{ textDecoration: 'none' ,marginTop : 5,paddingRight: 15 }}>Industry Connect</NavLink>
            <NavLink tag = {Link} to="/locations" style={{ textDecoration: 'none' ,marginTop : 5,paddingRight: 15}}>Locations and Partners</NavLink>
            <NavLink tag = {Link} to="/contactus" style={{ textDecoration: 'none' ,marginTop : 5}}>Contact Us</NavLink>
            
          </Nav>

          <Nav>
            <NavLink tag= {Link} to="/quote">
              <Button variant="light">
                Request a Quote
              </Button>
            </NavLink>
          </Nav>

        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
